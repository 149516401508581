import { REMOVE_ITEM_DETAIL_TIMEPERIOD, ITEM_CLICKED_DATA } from './constants';
import { IS_SERVER } from './checkRenderEnv';

/**
 *
 * @returns id of the product stored in session when user clicked it on PLP
 */
export function getItemClickedId() {
  if (!IS_SERVER) {
    return JSON.parse(sessionStorage?.getItem(ITEM_CLICKED_DATA))?.itemId;
  }
  return null;
}

/**
 * Scrolls the card into view if the data is saved in session storage, then removes the data from session storage.
 * @param {*} data
 * @param {*} cardRef
 */
export function scrollItemAndRemoveDetails(data, cardRef) {
  if (data?.id === getItemClickedId()) {
    cardRef?.current?.scrollIntoView({ behavior: 'auto', block: 'center' });
    setTimeout(() => {
      if (!IS_SERVER) {
        const sessionData = JSON.parse(sessionStorage?.getItem(ITEM_CLICKED_DATA));
        if (sessionData?.hasOwnProperty('itemId')) {
          delete sessionData['itemId'];
        }
        sessionStorage.setItem(ITEM_CLICKED_DATA, JSON.stringify(sessionData));
      }
    }, REMOVE_ITEM_DETAIL_TIMEPERIOD);
  }
}
